import firebase from "firebase"
import { isBrowser } from "services/general"
import { getSignedInUser } from "../../Auth/services/user"

export const addAddress = async ({ values, callback }) => {
  if (isBrowser()) {
    let { province, city, streetAddress, addressType } = values.address
    let addresses = getSignedInUser()?.addresses
    let userData = getSignedInUser()?.userData
    let addressesId = addresses?.id
    let addressData = {
      province: province?.value,
      type: addressType,
      streetAddress: streetAddress,
      city: city?.value,
      primary: false,
    }
    let currentAddressList = addresses?.addresses || []
    let tempAddresses = [...currentAddressList]
    if (tempAddresses?.length < 1) addressData.primary = true
    tempAddresses.push(addressData)
    let addressDocument = { addresses: tempAddresses }

    if (addressesId) {
      await firebase
        .firestore()
        .collection("addresses")
        .doc(addressesId)
        .update(addressDocument)
    } else {
      let response = await firebase
        .firestore()
        .collection("addresses")
        .add(addressDocument)

      let newAddressId = response.id
      await firebase
        .firestore()
        .collection("users")
        .doc(userData?.id)
        .update({ addresses: newAddressId })

      sessionStorage.setItem(
        "userData",
        JSON.stringify({ ...userData, addresses: newAddressId })
      )
      addressesId = newAddressId
    }

    addressDocument = { ...addressDocument, id: addressesId }

    sessionStorage.setItem("addresses", JSON.stringify(addressDocument))
    if (callback) callback()
  }
}

export const editAddress = ({ values, callback }) => {
  if (isBrowser()) {
    let {
      province,
      city,
      streetAddress,
      addressType,
      primary,
    } = values?.address
    let addresses = getSignedInUser()?.addresses
    let addressesId = addresses.id
    let addressData = {
      province: province?.value,
      type: addressType,
      streetAddress: streetAddress,
      city: city?.value,
    }
    if (primary) addressData.primary = true

    let currentAddressList = addresses?.addresses || []
    let tempAddresses = [...currentAddressList]
    tempAddresses.splice(values.index, 1, addressData)
    let addressDocument = { addresses: tempAddresses }

    firebase
      .firestore()
      .collection("addresses")
      .doc(addressesId)
      .update(addressDocument)

    addressDocument = { ...addressDocument, id: addressesId }

    sessionStorage.setItem("addresses", JSON.stringify(addressDocument))
    if (callback) callback()
  }
}

export const deleteAddress = ({ index, callback }) => {
  if (isBrowser()) {
    let addresses = getSignedInUser()?.addresses
    let addressesId = addresses.id
    let currentAddressList = addresses?.addresses || []
    let tempAddresses = [...currentAddressList]
    tempAddresses.splice(index, 1)
    let addressDocument = { addresses: tempAddresses }

    firebase
      .firestore()
      .collection("addresses")
      .doc(addressesId)
      .update(addressDocument)

    addressDocument = { ...addressDocument, id: addressesId }
    sessionStorage.setItem("addresses", JSON.stringify(addressDocument))

    if (callback) callback(addressDocument)
  }
}

export const makeDefaultAddress = ({ index, callback }) => {
  if (isBrowser()) {
    let addresses = getSignedInUser()?.addresses
    let addressesId = addresses.id
    let currentAddressList = addresses?.addresses || []
    let newPrimaryAddress = currentAddressList[index]
    newPrimaryAddress.primary = true
    let tempAddresses = [...currentAddressList]
    tempAddresses.splice(index, 1)

    tempAddresses = tempAddresses.map((address) => {
      address.primary = false
      return address
    })
    tempAddresses.unshift(newPrimaryAddress)

    let addressDocument = { addresses: tempAddresses }

    firebase
      .firestore()
      .collection("addresses")
      .doc(addressesId)
      .update(addressDocument)

    addressDocument = { ...addressDocument, id: addressesId }

    sessionStorage.setItem("addresses", JSON.stringify(addressDocument))
    if (callback) callback(tempAddresses)
  }
}
